<template>
  <v-card
    class="cardContent"
    max-height="273"
    min-width="370"
    min-height="273"
    style="cursor: pointer"
    :ripple="false"
  >
    <v-col @click.prevent="cardClicked()">
      <v-row class="topRow">
        <v-col
          class="flex-grow-0 pl-0 align-self-center"
          style="min-width: 9px !important"
        >
          <svg
            v-if="unRead == true"
            xmlns="http://www.w3.org/2000/svg"
            width="9"
            height="47"
            viewBox="0 0 9 47"
          >
            <rect
              id="Rectangle_3502"
              data-name="Rectangle 3502"
              width="9"
              height="47"
              :fill="unReadColor"
            />
          </svg>
        </v-col>
        <v-col>
          <v-row>
            <v-col class="pb-0 textDecorationBold font14" align="left">
              {{ timeAgo }}
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0 font14" align="left">
              {{ createdDateString }}
            </v-col>
          </v-row>
        </v-col>
        <v-col class="flex-grow-0 pr-6">
          <v-row v-if="!['-','', null, undefined].includes(rating)" class="flex-nowrap" align="center">
            <v-col class="pr-0">
              <v-rating
                dense
                color="#243774"
                background-color="#243774 !important"
                :length="1"
                :readonly="true"
                size="24"
              />
            </v-col>
            <v-col class="textDecorationBold font14 primaryColor pl-0">
              <span> {{ rating }} </span></v-col
            >
          </v-row>
        </v-col>
      </v-row>
      <v-row class="mt-0">
        <v-col class="pa-0">
          <v-divider />
        </v-col>
      </v-row>
      <v-row>
        <v-col align="left" class="pl-6">
          <v-row>
            <v-col v-if="fromDetails" class="align-self-center">
                 <div @click.stop="avatarView()">
              <AvatarField
                :data="fromDetails.img"
                :absolutePath="fromDetails.absolutePath"
                :isImg="fromDetails.isImg ? true : false"
                :showName="true"
                :nameContent="
                  (fromDetails.name ? fromDetails.name + ' ' : '') +
                  (fromDetails.surname ? fromDetails.surname : '')
                "
                fontSizeName="14px"
                :showSubContent="true"
                :subContent="fromDetails.company ? fromDetails.company : ''"
                :backgroundColor="fromDetails.backgroundColor ? fromDetails.backgroundColor : '#77BD86'"
                :size="32"
                fontSizeImg="16px"
                rowClass="primaryColor"
              />
              </div>
            </v-col>
            <v-col v-else class="align-self-center">
              <span> -- </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              :class="type ? 'goldText textDecorationBold' : ''"
              class="font14 pt-0 pb-1"
            >
              <span> {{ type ? type : "--" }} </span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="cusTab font14 pt-0 pb-1">
              <span> {{ textOverFlow(firstDisplay ? firstDisplay : "--", 32) }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="cusTab font14 pt-0 pb-1">
              <span> {{ textOverFlow(secondDisplay ? secondDisplay : "--", 32) }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="px-0">
          <v-divider />
        </v-col>
      </v-row>

      <v-row class="mt-2">
        <v-col align="left" class="pl-8">
          <MatchingLeadStatus :title="matchingLeads" :wrap="false" />
        </v-col>
        <v-col align="right" class="pr-6">
          <div @click.stop="estateView()">
            <ClickableBox
              :title="matchingObject.title"
              :titleBox="matchingObject.titleBox"
              titleColor="#263573"
              :titleOnHoverColor="
                matchingObject.titleBox
                  ? matchingObject.titleOnHoverColor
                  : '#263573'
              "
            />
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-card>
</template>
<script>
/* 
  Name:DemandCard
  Description: DemandCard component to generate quickly Request cards. Support approve buttons with actions, custom button with custom actions, show unread indication, selection of card, shows basic data and onClick card actions
  API Property   
    index: Number containing index of object in array
    unRead: Boolean variable to set request as read or unread
    unReadColor: String containing the color of the unread banner
    createdDate: String or Date containing created date information
    rating: String or number containing rating information
    type: String containing the type information
    firstDisplay: String containing the first display information
    secondDisplay: String containing the second display information
    matchingLeads: Boolean if the demand matching with a specific estate
    matchingObject: Box with estate title and code
    similarityText: Text containing description of similarity
    similarity: String or number containing value of similarity
    fromDetails: Object containing fromDetails. For example:
                  { name: "Ioannis", surname: "Katsarakis", img: "/uploads/images/image1.png" }
    onClickHandler:  Handler function from parent when card is clicked,
    handleEstateView:  Handler function from parent when card is clicked,
    handleAvatarView:  Handler function from parent when card is clicked,
    estateId: Number for the estate id

    Components Used:
      AvatarField 
      ClickableBox 
      MatchingLeadStatus
*/
import AvatarField from "@/components/Fields/AvatarField/AvatarField.vue";
import CoreHelper from "@/helpers/Core/core.js";
import ClickableBox from "@/components/Fields/ClickableBox/ClickableBox.vue";
import MatchingLeadStatus from "@/components/Fields/MatchingLeadStatus/MatchingLeadStatus.vue";

export default {
  name: "DemandCard",
  props: {
    index: Number,
    unRead: { type: Boolean, default: false },
    unReadColor: { type: String, default: "#eb1f28" },
    createdDate: [String, Number],
    rating: [String, Number],
    fromDetails: Object,
    matchingObject: Object,
    matchingLeads: Boolean,
    type: String,
    firstDisplay: [String, Number],
    secondDisplay: [String, Number],
    similarityText: String,
    similarity: [String, Number],
    estateId: Number,
    onClickHandler: Function,
    onEstateHandler: Function,
    onAvatarHandler: Function,
  },
  components: { AvatarField, ClickableBox, MatchingLeadStatus },
  data() {
    return {};
  },
  computed: {
    createdDateString() {
      if (this.createdDate) {
        return isNaN(this.createdDate) == true
          ? this.createdDate
          : CoreHelper.EpochToDateTime(this.createdDate, "|");
      } else {
        return "--";
      }
    },
    timeAgo() {
      try {
        return CoreHelper.time_ago_card(this.createdDate, true);
      } catch {
        return "--";
      }
    },
    getSimilarity() {
      return isNaN(this.similarity) == true
        ? this.similarity
        : this.similarity + "%";
    },
  },
  methods: {
    cardClicked() {
      try {
        this.onClickHandler(this.index);
      } catch (e) {
        console.error(e);
      }
    },
    estateView() {
      try {
        this.onEstateHandler(this.estateId ? this.estateId : null);
      } catch (e) {
        console.eror(e);
      }
    },
    avatarView() {
      try {
        this.onAvatarHandler(this.fromDetails.id);
      } catch (e) {
        console.eror(e);
      }
    },
    textOverFlow(text, length) {
      try {
        return text.length <= length ? text : text.substr(0, length) + " ..";
      } catch {
        return text;
      }
    },

    approveAction(val) {
      try {
        this.approveHandler(this.index, val);
      } catch (e) {
        console.error(e);
      }
    },
    customBtnClicked() {
      try {
        this.customBtnHandler(this.index);
      } catch (e) {
        console.eror(e);
      }
    },
    getPhoto(obj) {
      if (![undefined, "", null].includes(obj.img)) {
        return obj.img;
      } else {
        if (obj.name && obj.surname) {
          return obj.name.charAt(0) + obj.surname.charAt(0);
        } else if (obj.name) {
          return obj.name.charAt(1);
        } else if (obj.surname) {
          return obj.surname.charAt(1);
        }
        return "";
      }
    },
  },
};
</script>
<style scoped>
.font14 {
  font-size: 14px;
}

.topRow {
  height: 85px !important;
  min-height: 85px !important;
  max-height: 85px !important;
}

/* .midRow {
  height: 120px !important;
  min-height: 120px !important;
  max-height: 120px !important;
} */

.botRow {
  height: 60px !important;
  min-height: 60px !important;
  max-height: 60px !important;
}

.cardText {
  font-size: 14px !important;
  color: #263573 !important;
  font-weight: 200 !important;
  font-family: InterBold;
}

.iconFlip {
  transform: rotateY(180deg);
}

.approveClass {
  font-size: 13px;
  background-color: #77bd85 !important;
  border-radius: 0px !important;
  text-transform: capitalize !important;
}

.rejectClass {
  font-size: 13px;
  background-color: #666666 !important;
  border-radius: 0px !important;
  text-transform: capitalize !important;
}

.cardContent {
  margin: 10px 12px 10px 0px;
}
</style>