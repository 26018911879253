<template>
  <v-col>
    <v-row id="topAreaID">
      <v-col>
        <v-row class="mt-0">
          <v-col>
            <v-row class="align-center justify-space-between topRow">
              <v-col
                :cols="tabletBreakpoint ? 12 : ''"
                class="pr-0"
                :class="{'flex-grow-0 text-no-wrap' : !tabletBreakpoint}"
                :align="'left'"
                align-self="start"
              >
                <span
                  :style="'font-size: 16px !important; font-family: CenturyGothicBold !important;'"
                >
                  {{
                    $t("youHave2") + " (" + unReadCNT + ") " + $t("newRequests")
                  }}
                </span>
              </v-col>
              <v-col class="pb-0">
                <v-row
                  class="align-center"
                  :class="
                    tabletBreakpoint
                      ? 'justify-start'
                      : 'topFirstRow justify-end'
                  "
                >
                  <v-col
                    v-if="['Admin'].includes(userDetails.Role)"
                    align="left"
                    :class="
                      !tabletBreakpoint ? 'flex-grow-0' : 'pl-0 flex-grow-0'
                    "
                  >
                    <v-btn
                      class="gray-btn textDecorationBold pr-3"
                      outlined
                      :height="36"
                      :width="tabletBreakpoint ? 120 : 180"
                      :loading="expListloader"
                      @click="exportListClicked"
                    >
                      Export List
                    </v-btn>
                  </v-col>
                  <v-col
                    :cols="mobileBreakpoint ? 12 : tabletBreakpoint ? 6 : ''"
                    align="left"
                    :class="tabletBreakpoint ? 'pl-0' : 'flex-grow-0 pl-2'"
                  >
                    <v-btn
                      class="green-btn textDecorationBold pr-3"
                      :class="{ active: isActiveMatching }"
                      @click="toggleMatching"
                    >
                      <span style="color: white">
                        {{
                          isActiveMatching
                            ? $t("completed2")
                            : $t("relevantDemands")
                        }}
                      </span>
                    </v-btn>
                  </v-col>
                  <v-col
                    v-if="isAuthorized('add') == true"
                    class="flex-grow-0 pl-0"
                    :align="tabletBreakpoint ? 'left' : ''"
                  >
                    <v-btn
                      class="btn-custom"
                      @click="createHandler()"
                      color="error"
                    >
                      <Icon
                        v-if="mobileBreakpoint == true"
                        icon="mdi:plus-thick"
                        height="25"
                        outline
                      />
                      <span v-else> {{ $t("addDemand") }} </span>
                    </v-btn>
                  </v-col>
                  <v-col
                    class="flex-grow-0 pl-2"
                    :align="tabletBreakpoint ? 'left' : ''"
                  >
                    <v-btn
                      class="btn-custom"
                      @click="filtersBtn(2)"
                      key="fBtn2"
                      outlined
                      color="primary"
                    >
                      <Icon
                        icon="bx:filter-alt"
                        height="25"
                        outline
                        color="#263573"
                      />
                    </v-btn>
                  </v-col>
                  <v-col class="flex-grow-0 pr-2 pl-2" v-if="!tabletBreakpoint">
                    <v-btn-toggle
                      dense
                      v-model="viewChoice"
                      group
                      tile
                      mandatory
                      color="primary"
                    >
                      <v-btn
                        :id="viewChoice == 0 ? 'active-button' : ''"
                        class="mr-1"
                      >
                        <Icon
                          icon="clarity:grid-view-solid"
                          height="25"
                          :color="viewChoice == 0 ? 'white' : '#263573'"
                        />
                      </v-btn>
                      <v-btn
                        :id="viewChoice == 1 ? 'active-button' : ''"
                        class="mr-3 hideOnMedia"
                      >
                        <Icon
                          icon="ant-design:unordered-list-outlined"
                          height="30"
                          :color="viewChoice == 1 ? 'white' : '#263573'"
                        />
                      </v-btn>
                    </v-btn-toggle>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="justify-space-between locRow">
          <v-col
            class="pl-0 pr-0 pb-0"
            cols="12"
            v-if="tabletBreakpoint"
            :class="mobileBreakpoint == true ? '' : 'flex-grow-0'"
          >
            <v-pagination
              class="main-pgt"
              v-model="options.page"
              :total-visible="6"
              @input="fetchDemands"
              :length="totalPages"
            >
            </v-pagination>
          </v-col>
          <v-col cols="12" xs="12" sm="8" md="4" lg="4">
            <v-row class="justify-start HRow flex-nowrap">
              <v-col class="flex-grow-0 HCol pb-0">
                <v-row
                  class="leftHRow flex-nowrap"
                  :class="tabletBreakpoint == true ? 'justify-start' : ''"
                >
                  <v-col class="flex-grow-0 pr-0">
                    <v-select
                      :items="requestFilters"
                      dense
                      v-model="filterRequest"
                      item-text="text"
                      item-value="val"
                      color="primary"
                      item-color="primary"
                      style="
                        font-size: 15px;
                        font-family: CenturyGothicBold;
                        font-weight: 500;
                      "
                      height="43.54"
                      hide-details
                      outlined
                      return-object
                      @change="optionsRefresh"
                      :placeholder="$t('all')"
                    >
                      <template v-slot:selection="{ item }">
                        <v-row class="flex-grow-0">
                          <v-col
                            class="d-flex text-no-wrap primaryColor textDecorationBold"
                            style="align-items: center"
                          >
                            {{ $t(item.text) }}
                          </v-col>
                        </v-row>
                      </template>
                      <template v-slot:item="{ item }">
                        <v-list>
                          <v-list-item-title>
                            <v-row>
                              <v-col
                                class="primaryColor textDecorationBold"
                                style="display: flex; align-items: center"
                              >
                                {{ $t(item.text) }}
                              </v-col>
                            </v-row>
                          </v-list-item-title>
                        </v-list>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col class="flex-grow-0 pr-0">
                    <v-select
                      :items="itemsRefresh"
                      dense
                      v-model="options.items"
                      color="primary"
                      item-color="primary"
                      @change="optionsRefresh"
                      style="
                        max-width: 75px;
                        font-size: 14px;
                        font-family: CenturyGothicBold;
                        font-weight: 500;
                      "
                      height="43.54"
                      hide-details
                      outlined
                    >
                      <template v-slot:selection="{ item }">
                        <span
                          class="font14 textDecorationBold primaryColor text-truncate"
                        >
                          {{ item }}
                        </span> </template
                      ><template slot="item" slot-scope="data">
                        <v-list>
                          <v-list-item-title>
                            <v-row>
                              <v-col>
                                <span
                                  class="font14 textDecorationBold primaryColor text-truncate"
                                >
                                  {{ data.item }}
                                </span>
                              </v-col>
                            </v-row>
                          </v-list-item-title>
                        </v-list>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col
                    class="pl-0 pr-0 pt-5 pb-0 flex-grow-0"
                    v-if="tabletBreakpoint"
                  >
                    <SortColumns
                      v-if="[0, 1, 2].includes(viewChoice)"
                      :title="
                        Object.keys(sort[0]).length === 0 && viewChoice == 1
                          ? $t('showSortOption')
                          : $t('sort')
                      "
                      :selectedTitle="
                        Object.keys(sort[0]).length === 0
                          ? null
                          : $t(sort[0].text)
                      "
                      :signleChoice="true"
                      :columns="sortFilters"
                      :onSubmit="updateSortCols"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                xs="12"
                sm="12"
                md="12"
                lg="9"
                xl="8"
                class="pb-0"
              >
                <Location
                  :placeholder="$t('searchLoc') + '...'"
                  :showNewDealList="false"
                  iconColor="primary"
                  :vuexProps="false"
                  :setLocation="setACLocation"
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col class="pr-0 rightHRow" v-if="!tabletBreakpoint">
            <v-row class="topSecondRow justify-end">
              <v-col class="pl-0 pr-0 pt-5 pb-0 flex-grow-0">
                <SortColumns
                  v-if="[0, 1, 2].includes(viewChoice)"
                  :title="
                    Object.keys(sort[0]).length === 0 && viewChoice == 1
                      ? $t('showSortOption')
                      : $t('sort')
                  "
                  :selectedTitle="
                    Object.keys(sort[0]).length === 0 ? null : $t(sort[0].text)
                  "
                  :signleChoice="true"
                  :columns="sortFilters"
                  :onSubmit="updateSortCols"
                />
              </v-col>
              <v-col
                class="pb-0"
                :class="
                  mobileBreakpoint == true ? '' : 'flex-grow-0 pl-0 pr-0 pt-5'
                "
              >
                <HeaderColumns
                  v-if="viewChoice == 1"
                  :onSubmit="updateHeaderCols"
                  :headers="headers"
                  :selectedHeaderColumns="headerColumns"
                />
              </v-col>
              <v-col
                class="pl-0 pr-0 pb-0"
                :class="mobileBreakpoint == true ? '' : 'flex-grow-0'"
              >
                <v-pagination
                  class="main-pgt"
                  v-model="options.page"
                  :total-visible="6"
                  @input="fetchDemands"
                  :length="totalPages"
                >
                </v-pagination>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-1">
          <v-col align="left" class="d-flex flex-nowrap overflow-auto">
            <FilterTag
              v-for="(tag, index) in tagFilterArray"
              :key="index"
              :display="tag.display"
              :deleteHandler="deleteFilter"
              :attrType="tag.attrType"
              :attrHead="tag.attrHead"
              :attrIndex="tag.attrIndex"
              :attrValue="tag.attrValue"
              :supportDelete="tag.noDelete ? false : true"
            />
            <a
              v-if="tagFilterArray && tagFilterArray.length > 0"
              class="text-decoration-underline text-no-wrap"
              color="#43425D"
              @click="clearAllFilter"
            >
              {{ $t("clear") }}</a
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-row
          v-if="viewChoice != 2 && (loading || dataFoundStatus != 'true')"
          justify="center"
        >
          <LoaderGif v-if="loading" />
          <NoData
            v-else-if="this.dataFoundStatus == 'false'"
            :showBtn="true"
            :btnTxt="$t('editFilters')"
            :btnClicked="() => openDialog('filters', null)"
          />
          <ServerProblem v-else-if="this.dataFoundStatus == 'error'" />
        </v-row>
        <v-row
          v-else-if="viewChoice == 0"
          class="mb-6 noScrollBar demandCards"
          :style="
            !mobileBreakpoint
              ? 'min-height: 450px !important; max-height:' +
                getReportHeight +
                'px; overflow: auto;'
              : ''
          "
          :class="mobileBreakpoint ? 'justify-center' : 'justify-start'"
        >
          <DemandCard
            :style="mobileBreakpoint ? 'width: 100% !important' : 'max-width: 370px;'"
            v-for="d in data"
            :key="d['_id']"
            :index="d['_id']"
            :unRead="d.unRead"
            :class="d['_id'] == demandOpened ? ' demandOpened' : ''"
            :createdDate="d.RegistrationDate ? d.RegistrationDate : null"
            :rating="d.ratingValue"
            :matchingLeads="d.MatchingLeads"
            :fromDetails="d.fromDetails"
            :matchingObject="{
              title: d.estateCodeTitle,
              titleOnHoverColor: d.estateBoxColor,
              titleColor: d.estateTitleColor,
              titleBox: d.titleBox,
              rowClass: 'primaryColor textDecorationBold',
            }"
            :type="d.cardType"
            :firstDisplay="
              d['cardCharacteristics']
                ? d.cardCharacteristics.length > 0
                  ? d.cardCharacteristics[0]
                  : '--'
                : '--'
            "
            :secondDisplay="
              d['cardCharacteristics']
                ? d.cardCharacteristics.length > 1
                  ? d.cardCharacteristics[1]
                  : '--'
                : '--'
            "
            :estateId="
              d['Estate'] ? (d.Estate._id ? d.Estate._id : null) : null
            "
            :similarityText="d['similarityText'] ? d.similarityText : '--'"
            :similarity="d['similarity'] ? d.similarity : '--'"
            :onClickHandler="demandHandler"
            :onEstateHandler="getSpecificEstate"
            :onAvatarHandler="fetchContactById"
          />
        </v-row>
        <v-row v-else-if="viewChoice == 1" class="ml-0">
          <CustomTable
            class="row-height-75 pl-0 pr-0 fixed-action-column"
            style="overflow-y: auto"
            source="Demands"
            :itemsPerPage="options.items"
            :headers="reportHeaderColumns"
            :tableHeight="getTableHeight"
            :fixedHeader="true"
            :templates="templates"
            :data="data"
            :supportDelete="true"
            :supportEdit="true"
            :supportView="true"
            :loading="loading"
            :handleDelete="onDelete"
            :handleEdit="onEdit"
            :handleView="onView"
            :handleEstateInfoView="onView"
            :clickedAvatar="clickedAvatar"
          />
        </v-row>
      </v-col>
    </v-row>
    <v-footer
      inset
      padless
      absolute
      style="background-color: #ebebeb"
      v-if="data.length > 0"
      :class="mobileBreakpoint == true ? 'pb-10' : ''"
    >
      <v-row class="mb-0" style="max-width: 100% !important">
        <v-col align="left" class="main-pgt pt-4 footerCl">
          {{ $t("footerP1") }} {{ options.page ? options.page : 0 }}
          {{ $t("footerP2") }} {{ options.items ? options.items : 0 }}
          {{ $t("footerP3") }} {{ totalResult ? totalResult : 0 }}
          {{ $t("footerP4") }}
        </v-col>
      </v-row>
    </v-footer>
    <CreateEditDemand
      :content="dialogArray[dialogArray.length - 1].data"
      :type="dialogArray[dialogArray.length - 1].type"
      :onCloseHandler="closeDialog"
      :onCloseEdit="onCloseEdit"
      :onCloseAfterCreate="closeAfterCreate"
      v-if="
        ['edit', 'create'].includes(dialogArray[dialogArray.length - 1].type) &&
        dialogArray[dialogArray.length - 1].open == true
      "
    />
    <DemandsFilters
      v-if="
        dialogArray[dialogArray.length - 1].type == 'filters' &&
        dialogArray[dialogArray.length - 1].open == true
      "
      :onCloseHandler="closeDialog"
      :filterValues="filterValues"
      :updateAttribute="updateFieldValue"
      :filterType="filterSelection"
      :onSearch="
        (m) => {
          if (m == true) {
            this.isRefresh = true;
          }
          closeDialog();
          fetchDemands();
        }
      "
    />
    <DemandSlider
      v-if="
        dialogArray[dialogArray.length - 1].type == 'demand' &&
        dialogArray[dialogArray.length - 1].open == true
      "
      :data="dialogArray[dialogArray.length - 1].data"
      :canEdit="true"
      :onEditHandler="onEdit"
      :canDelete="true"
      :onDeleteHandler="onDelete"
      :closeDemandSlider="closeDialog"
      :fetchContactById="fetchContactById"
      :getSpecificEstate="getSpecificEstate"
      :editValuationHandler="
        (newData) =>
          editValuationHandler(
            dialogArray[dialogArray.length - 1].data,
            newData
          )
      "
      :fetchDemands="
        (newData) =>
          fetchDemands('specific', dialogArray[dialogArray.length - 1].data._id)
      "
      :loading="loading"
    />
    <RealEstateSlider
      v-if="dialogArray[dialogArray.length - 1].type == 'card'"
      :data="dialogArray[dialogArray.length - 1].data"
      :openMediaSlider="openDialog"
      :fetchContactById="fetchContactById"
      :uploadSuccessHandler="uploadDocSuccess"
      @close="closeDialog"
      :deleteEstate="false"
      :editEstate="false"
    />
    <MediaSlider
      v-if="dialogArray[dialogArray.length - 1].type == 'media'"
      :data="dialogArray[dialogArray.length - 1].data"
      :drawer="dialogArray[dialogArray.length - 1].open"
      :close="closeDialog"
      :onEdit="openDialog"
      :editEstate="false"
    />
    <ContactSlider
      v-if="
        dialogArray[dialogArray.length - 1].type == 'viewSlider' &&
        dialogArray[dialogArray.length - 1].open
      "
      :closeHandler="closeDialog"
      :openEditSlider="openDialog"
      :openDemandSlider="openDemandSlider"
      :data="dialogArray[dialogArray.length - 1].data"
      :openRealEstateSlider="openRealEstateSlider"
      :updateIsSavedHandler="
        (m) => {
          dialogArray[dialogArray.length - 1].data.isSaved = m;
        }
      "
      :editProfile="false"
    />
    <v-dialog
      v-model="deleteDialog.open"
      v-if="deleteDialog.type == 'delete'"
      width="500"
    >
      <DeletePopup
        :onRemove="deleteDemand"
        :onCancel="closeDeleteDialog"
        cancelText="Ακύρωση"
        deleteText="Ναι, είμαι σίγουρος"
        :content="deleteDialog.content"
        :item="deleteDialog.data"
        :loading="deleteLoading"
      />
    </v-dialog>
    <v-dialog v-model="messageDialog.open" width="500">
      <PopupMessage
        :content="messageDialog.content"
        :type="messageDialog.type"
      />
    </v-dialog>
  </v-col>
</template>

<script>
/* 
  Name: Demands
  Description: Demands view is the main page for the demands. In this page there is the demands from the clients for real estates (specific or general). Also there is filters, cards, report and slider view for each demand. On top on this page we have filters also.
  
  Components Used:
    Location
    Icon
    CreateEditDemand
    DemandsFilters
    DemandCard
    CustomTable
    HeaderColumns
    DemandSlider
    SortColumns
    ServerProblem
    NoData
    LoaderGif
    PopupMessage
    RealEstateSlider
    MediaSlider
    ContactSlider
    DeletePopup
    FilterTag

  Endpoints Functions Called:
  -RequestsDemandRepo
    getReportData
    get
  
  -RealEstateRepo
    get
    getId

  -ContactRepo
    get
*/

import AuthHelper from "@/helpers/Core/auth.js";
import { mapGetters, mapState } from "vuex";
import { Icon } from "@iconify/vue2";
import Location from "@/components/Maps/Location.vue";
import CreateEditDemand from "@/components/Demands/CreateEditDemand";
import DemandsFilters from "@/components/Demands/Filters.vue";
import DemandsModel from "@/static/demands/demands.js";
import DemandCard from "@/components/Demands/DemandCard.vue";
import CustomTable from "@/components/CustomTable/CustomTable.vue";
import i18n from "@/i18n/i18n";
import HeaderColumns from "@/components/CustomTable/HeaderColumns.vue";
import DemandSlider from "@/components/Demands/Slider/DemandSlider.vue";
import Repository from "@/services/Repository";
import DemandHelper from "@/helpers/Demand/demand.js";
import CoreHelper from "@/helpers/Core/core";
import SortColumns from "@/components/CustomTable/SortColumns.vue";
import ServerProblem from "@/components/Popups/ServerProblem.vue";
import NoData from "@/components/Popups/NoData.vue";
import LoaderGif from "@/components/Popups/LoaderGif.vue";
import RealEstateSlider from "@/components/RealEstate/RealEstateSlider/RealEstateSlider.vue";
import ContactsHelper from "@/helpers/Contacts/contacts.js";
import PopupMessage from "@/components/Popups/PopupMessage.vue";
import MediaSlider from "@/components/RealEstate/MediaSlider";
import ContactSlider from "@/components/Contacts/ContactSlider/ContactSlider";
import DeletePopup from "@/components/Popups/DeletePopup.vue";
import FilterTag from "@/components/Filters/FilterTag.vue";
//import RealEstateHelper from "@/helpers/RealEstate/realEstates.js";
const RequestsDemandRepo = Repository.get("reqDemand");
const RealEstateRepo = Repository.get("realEstate");
const ContactRepo = Repository.get("contacts");

export default {
  name: "Demands",
  components: {
    Location,
    Icon,
    CreateEditDemand,
    DemandsFilters,
    DemandCard,
    CustomTable,
    HeaderColumns,
    DemandSlider,
    SortColumns,
    ServerProblem,
    NoData,
    LoaderGif,
    PopupMessage,
    RealEstateSlider,
    MediaSlider,
    ContactSlider,
    DeletePopup,
    FilterTag,
  },
  async created() {
    if(this.tabletBreakpoint) this.viewChoice = 0;
    this.currency = CoreHelper.getCurrency(true);
    this.fetchDemands();
    this.getHeight();
    this.updateFieldValue("companyId", this.userDetails.LastCompany);
    this.updateFieldValue("associateId", this.userDetails._id);
  },
  data() {
    return {
      viewChoice: 1, //0:card, 1:Report
      totalPages: 0,
      totalResult: 0,
      options: {
        page: 1,
        items: 20,
      },
      personalData: null,
      dataFoundStatus: null,
      expListloader: false,
      disableWatch: false,
      headers: DemandsModel.headers,
      headerColumns: DemandsModel.selectedHeaders,
      templates: DemandsModel.templates,
      filterValues: DemandsModel.filterValues,
      sortFilters: DemandsModel.sortFilters,
      requestFilters: DemandsModel.requestFilters,
      filterSelection: null,
      filterObject: [],
      itemsRefresh: [20, 30, 60, 90],
      loading: false,
      height: null,
      windowHeight: null,
      windowWidth: null,
      demandOpened: null,
      estatesData: [],
      deleteLoading: false,
      messageDialog: {
        type: null,
        open: false,
        content: null,
      },
      deleteDialog: {
        open: false,
        data: null,
        type: null,
        content: null,
      },
      dialog: {
        open: false,
        type: null,
        data: null,
      },
      dialogArray: [
        {
          open: false,
          type: null,
          data: null,
        },
      ],
      data: [],
      currency: null,
      unReadCNT: 0,
    };
  },
  mounted() {
    window.addEventListener("resize", this.getHeight);
  },
  unmounted() {
    window.removeEventListener("resize", this.getHeight);
  },
  watch: {
    viewChoice() {
      this.$nextTick(() => {
        this.getHeight();
      });
    },
    mobileBreakpoint() {
      if (this.mobileBreakpoint) this.viewChoice = 0;
    },
    tabletBreakpoint() {
      if (this.tabletBreakpoint) this.viewChoice = 0;
    },
  },
  computed: {
    ...mapGetters(["getItemsList"]),
    ...mapState({
      realEstateFilter: (state) => state.realEstate,
      userDetails: (state) => state.auth.userDetails,
    }),
    mobileBreakpoint() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    tabletBreakpoint() {
      return this.$vuetify.breakpoint.width < 800;
    },
    sort() {
      let lSort = this.sortFilters.filter((f) => {
        return f.sort;
      });
      return lSort.length == 0 ? [{}] : lSort;
    },
    locationGoogle: {
      get() {
        return this.filterValues?.locationGoogle;
      },
      set(value) {
        this.updateFieldValue(
          "locationGoogle",
          value == null || value == undefined ? null : value
        );
      },
    },
    filterRequest: {
      get() {
        return this.filterValues?.filterRequest;
      },
      set(value) {
        this.updateFieldValue(
          "filterRequest",
          value == null || value == undefined ? null : value
        );
      },
    },

    isActiveMatching: {
      get() {
        return this.filterValues?.isActiveMatching;
      },
      set(value) {
        this.updateFieldValue(
          "isActiveMatching",
          value == null || value == undefined ? null : value
        );
      },
    },
    reportHeaderColumns() {
      return this.headerColumns.map((m) => {
        m.text = i18n.t(m.text);
        return m;
      });
    },
    getTableHeight() {
      return this.windowHeight - this.height - 130 > 275 &&
        this.windowWidth > 600
        ? this.windowHeight - this.height - 250
        : this.windowWidth > 600
        ? 275
        : 400;
    },
    getReportHeight() {
      return this.windowHeight - this.height - 250;
    },
    tagFilterArray() {
      return this.filterObject[0];
    },
  },

  methods: {
    openDemandSlider(data) {
      this.openDialog("demand", data);
    },
    async onCloseEdit(avatarId) {
      try {
        let object = {};
        let array = [];
        let resp = [];
        this.loading = true;
        let params;
        params = {
          page: 0,
          items: 1,
        };
        object = { $and: [{ _id: avatarId }] };
        resp = await RequestsDemandRepo.get(object, params);
        if (resp.data != undefined && resp.data.requests != undefined) {
          if (resp.data.requests.length > 0) {
            let initData = DemandHelper.initData(resp.data, this.currency);
            array = initData[0];
            let index = this.data.findIndex((d) => {
              return d._id === array[0]._id;
            });
            this.data[index] = array[0];
            this.closeDialog();
            this.closeDialog();
            this.openDialog("demand", array[0]);
          }
        }
        this.loading = false;
      } catch (e) {
        this.loading = false;
        throw e;
      }
    },
    setACLocation(loc) {
      this.locationGoogle = loc;
      this.fetchDemands();
    },
    async deleteFilter(attrHead, attrType, attrValue, attrIndex) {
      if (attrType == "string") {
        for (let i = 0; i < attrHead.length; i++) {
          this.updateFieldValue(attrHead[i].name, null);
        }
        if (attrHead[0].name == "category") {
          await this.updateFieldValue("subCategory", null);
        }
      } else if (attrType == "array") {
        for (let i = 0; i < attrHead.length; i++) {
          this.updateFieldValue(attrHead[i].name, null, attrIndex);
        }
      } else if (attrType == "arrayByVal") {
        let findArray = this.filterValues[attrHead[0].name];
        let indexVal = findArray.findIndex((object) => {
          return object == attrValue;
        });
        findArray.splice(indexVal, 1);
        for (let i = 0; i < attrHead.length; i++) {
          await this.updateFieldValue(attrHead[i].name, findArray);
        }
      }
      await this.fetchDemands();
    },

    async exportListClicked() {
      try {
        this.loading = true;
        this.expListloader = true;
        let object;
        this.filterObject = await DemandHelper.setFilterObject(
          this.filterValues
        );
        object = this.filterObject[1];
        let params;
        if (Object.keys(this.sort[0]).length === 0) {
          params = {
            page: this.options.page - 1,
            items: this.options.items,
          };
        } else {
          let lSort = await CoreHelper.customStringify(
            this.sort[0].value,
            this.sort[0].sort == "asc" ? 1 : -1 /* 1 Asc, -1 Desc */
          );
          params = {
            page: this.options.page - 1,
            items: this.options.items,
            sort: lSort,
          };
        }
        let apiObject = {};

        if (object) {
          apiObject = object;
        }
        // object ? ({ $and: object}) : ({});
        await RequestsDemandRepo.getReportData(apiObject, params);
        this.loading = false;
        this.expListloader = false;
      } catch (e) {
        this.loading = false;
        this.expListloader = false;
        throw e;
      }
    },
    openRealEstateSlider(data) {
      this.openDialog("card", data);
    },
    toggleMatching() {
      this.isActiveMatching = !this.isActiveMatching;
      this.fetchDemands();
    },
    isAuthorized(action) {
      return AuthHelper.authorization(
        action,
        this.userDetails["_id"],
        this.userDetails["_id"]
      );
    },
    async clearAllFilter() {
      this.disableWatch = true;
      for (const key in this.filterValues) {
        if (key == "filterRequest") {
          this.updateFieldValue(key, {
            text: "all",
            val: 0,
          });
        } else {
          this.updateFieldValue(key, null);
        }
      }
      await this.fetchDemands();
      this.disableWatch = false;
    },
    uploadDocSuccess(estateId, docTypeProp, objVal) {
      const index = this.estatesData.findIndex((d) => {
        return d._id === estateId;
      });
      if (index != -1) {
        if (this.estatesData[index]["documents"] == undefined) {
          this.estatesData[index] = {
            ...this.estatesData[index],
            documents: {},
          };
        }
        if (this.estatesData[index]["documents"][docTypeProp])
          this.estatesData[index]["documents"][docTypeProp].push(objVal);
        else {
          let newObj = {};
          newObj[docTypeProp] = [objVal];
          this.estatesData[index]["documents"] = {
            ...this.estatesData[index]["documents"],
            ...newObj,
          };
          this.dialogArray[this.dialogArray.length - 1].data = {
            ...this.dialogArray[this.dialogArray.length - 1].data,
            documents: this.estatesData[index]["documents"],
          };
        }
      }
    },
    async getSpecificEstate(id) {
      let resp = await RealEstateRepo.getId(id);
      if (resp && resp.data && resp.data.estates) {
        this.openRealEstateSlider(
          resp.data.estates[0] ? resp.data.estates[0] : null
        );
      }
    },
    async fetchContactById(id) {
      let object = { _id: id };
      let params;
      params = {
        page: 0,
        items: 1,
      };
      var resp = await ContactRepo.get(object, params);
      if (resp.data != undefined && resp.data.contacts != undefined) {
        if (resp.data.contacts.length > 0) {
          this.personalData = resp.data.contacts.map((c) => {
            return ContactsHelper.initDataContact(c);
          });
        }
      }

      this.openDialog("viewSlider", this.personalData[0]);
      this.personalData = null;
      //this.dialog.type = "profileSlider";
    },
    openMessageDialog(type, content) {
      this.messageDialog = {
        open: true,
        type: type,
        content: content,
      };
    },
    closeMessageDialog() {
      this.messageDialog = {
        open: false,
        type: null,
        content: null,
      };
    },
    onDelete(item) {
      let content = i18n.t("demandDelete") + " " + item._id;
      this.openDeleteDialog("delete", item, content);
    },
    async deleteDemand(item) {
      try {
        await RequestsDemandRepo.delete(item["_id"]);
        this.fetchDemands();
      } catch (e) {
        console.error(e);
      }
      this.closeDeleteDialog();
      this.openMessageDialog("success", `Επιτυχής διαγραφή`);
      setTimeout(() => {
        this.closeMessageDialog();
      }, 2400);
    },
    onEdit(item) {
      this.demandOpened = item["_id"] ? item["_id"] : null;
      this.openDialog("edit", item);
    },
    onView(item) {
      this.demandOpened = item["_id"] ? item["_id"] : null;
      this.openDialog("demand", item);
    },
    updateFieldValue(key, value, index) {
      if (index == undefined) this.filterValues[key] = value;
      else {
        this.filterValues[key] = this.filterValues[key].filter((m, i) => {
          return i != index;
        });
      }
    },
    getHeight() {
      var element = document.getElementById("topAreaID");
      var positionInfo = element.getBoundingClientRect();
      this.height = positionInfo.height;
      this.windowHeight = window.innerHeight;
      this.windowWidth = window.innerWidth;
    },
    createHandler() {
      this.openDialog("create");
    },
    demandHandler(id) {
      let item = this.data.filter((m) => {
        if (m._id == id) {
          return m;
        }
      });
      this.demandOpened = item[0]["_id"];
      this.openDialog("demand", item[0]);
    },
    openDialog(type, data) {
      let obj = {};
      obj = { type: type, data: data, open: true };
      this.dialogArray.push(obj);
    },
    closeDialog() {
      if (this.dialogArray.length > 1) {
        //let popped = this.dialogArray[this.dialogArray.length - 1];
        this.dialogArray = this.dialogArray.filter(
          (obj, index) => index < this.dialogArray.length - 1
        );
      }
    },
    openDeleteDialog(type, data, content) {
      this.deleteDialog = {
        open: true,
        type: type,
        data: data,
        content: content,
      };
    },
    closeDeleteDialog() {
      this.deleteDialog = {
        open: false,
        type: null,
        data: null,
        content: null,
      };
    },
    closeEditDialog() {
      if (
        this.dialogArray[this.dialogArray.length - 1].type == "editEstate" &&
        this.dialogArray[this.dialogArray.length - 2].type == "card"
      ) {
        this.dialogArray = this.dialogArray.filter(
          (obj, index) => index < this.dialogArray.length - 2
        );
      } else {
        if (this.dialogArray.length > 1) {
          //let popped = this.dialogArray[this.dialogArray.length - 1];
          this.dialogArray = this.dialogArray.filter(
            (obj, index) => index < this.dialogArray.length - 1
          );
        }
      }
    },
    filtersBtn(type) {
      this.filterSelection = type;
      this.openDialog("filters", null);
    },
    async optionsRefresh() {
      this.options.page = 1;
      await this.fetchDemands();
    },
    updateHeaderCols(headerCols) {
      this.headerColumns = headerCols;
    },
    updateSortCols(sortCols) {
      if (sortCols.length > 0) {
        this.sortFilters.map((m) => {
          let sortCol = sortCols.find((col) => {
            if (col.value == m.value) {
              return col;
            }
          });
          m.sort = sortCol != undefined ? sortCol.sort : "";
          return m;
        });
      } else {
        this.sortFilters.map((m) => {
          m.sort = "";
          return m;
        });
      }
      this.fetchDemands();
    },
    clickedAvatar(id) {
      this.fetchContactById(id);
    },
    async fetchDemands(type, id) {
      try {
        let object = {};
        let array = [];
        let resp = [];

        this.loading = true;
        let params;
        if (type != "specific") {
          this.filterObject = await DemandHelper.setFilterObject(
            this.filterValues
          );

          object = this.filterObject[1];
          if (Object.keys(this.sort[0]).length === 0) {
            params = {
              page: this.options.page - 1,
              items: this.options.items,
            };
          } else {
            let lSort = await CoreHelper.customStringify(
              this.sort[0].value,
              this.sort[0].sort == "asc" ? 1 : -1 /* 1 Asc, -1 Desc */
            );
            params = {
              page: this.options.page - 1,
              items: this.options.items,
              sort: lSort,
            };
          }
          resp = await RequestsDemandRepo.get(object, params);
        } else {
          params = {
            page: 0,
            items: 1,
          };
          object = { $and: [{ _id: id }] };
          resp = await RequestsDemandRepo.get(object, params);
        }
        if (resp.data != undefined && resp.data.requests != undefined) {
          if (resp.data.requests.length > 0) {
            this.dataFoundStatus = "true";
            let initData = DemandHelper.initData(resp.data, this.currency);

            array = initData[0];
            this.unReadCNT = initData[1];
            if (type == "specific") {
              // Change the data to child
              this.dialogArray[this.dialogArray.length - 1].data = array[0];
              // Change the data to parent for the specific demand
              let index = this.data.findIndex((d) => {
                return d._id === array[0]._id;
              });
              this.data[index] = array[0];
            } else {
              this.data = array;
              this.totalResult = resp.data.results;
              this.totalPages = Math.ceil(
                this.totalResult / this.options.items
              );
              if (this.totalPages == 0) this.totalPages = 1;
            }
          } else {
            if (type != "specific") {
              this.dataFoundStatus = "false";
              this.data = [];
              this.totalResult = 0;
              this.totalPages = 0;
            }
          }
        }
        this.$nextTick(() => {
          this.getHeight();
        });
        this.loading = false;
      } catch (e) {
        this.dataFoundStatus = "false";
        this.loading = false;
        this.$nextTick(() => {
          this.getHeight();
        });
        throw e;
      }
    },
    editValuationHandler(data, newEval) {
      data.Client.customer_evaluation = newEval;
    },
    async closeAfterCreate(obj) {
      this.closeDialog();
      await this.fetchDemands();
      let index = this.data.findIndex((d) => {
        return d._id === obj.data.request_id;
      });
      this.openDialog("demand", this.data[index]);
    },
  },
};
</script>

<style scoped>
.btn-custom {
  text-transform: capitalize !important;
  font-family: CenturyGothicBold !important;
  font-weight: 500 !important;
  font-size: 15px;
  background-color: white;
}

.v-btn-toggle--group > .v-btn.v-btn {
  border-color: #263573 !important;
}

.demandOpened {
  background-color: rgba(88, 102, 155, 0.03);
  border: 2px solid #8893bf;
  mix-blend-mode: multiply;
}

.v-btn-toggle > .v-btn.v-btn {
  border-radius: 5px !important;
  border-width: thin !important;
  background-color: white !important;
}

.gray-btn {
  background-color: #dadada !important;
  color: #ffffff !important;
  font-size: 14 !important;
  border: unset !important;
  text-transform: capitalize !important;
}

.green-btn {
  background-color: #77bd86 !important;
  color: #77bd86 !important;
  font-size: 14 !important;
  border: unset !important;
  text-transform: capitalize !important;
}

#active-button {
  background-color: #263573 !important;
}

.footerCl {
  color: #3a3a40;
}

.main-pgt {
  font-size: 14px !important;
  /* font-family:CenturyGothicBold; */
}

.main-select {
  color: #263573 !important;
  border-color: #253472 !important;
  /* background-color: #263573; */
}

.theme--light.v-select .v-select__selections {
  color: #263573 !important;
}

.noScrollBar::-webkit-scrollbar {
  display: none;
}

.demandOpened {
  background-color: rgba(88, 102, 155, 0.03);
  border: 2px solid #8893bf;
  mix-blend-mode: multiply;
}
@media only screen and (max-width: 1700px) {
  .rightHRow {
    max-width: 500px;
  }
}

@media only screen and (max-width: 1389px) {
  .HRow {
    flex-direction: column-reverse;
  }

  .HCol {
    padding-top: 0px !important;
  }
}
@media only screen and (max-width: 1000px) {
  .hideOnMedia {
    display: none !important;
  }
}
@media only screen and (max-width: 545px) {
  .topFirstRow,
  .topSecondRow {
    justify-content: flex-start !important;
  }
}
@media only screen and (max-width: 753px) {
  .locRow {
    flex-direction: column;
  }
}
</style>
<style>
.fixed-action-column tr td:last-child,
thead.v-data-table-header tr th:last-child {
  position: sticky !important;
  position: -webkit-sticky !important;
  right: 0;
  z-index: 6;
  background: white;
}
thead.v-data-table-header tr th:last-child {
  z-index: 7 !important;
}
</style>
